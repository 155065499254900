/* global HP */

const stickyNav = document.querySelector('.js-site-header-fixed');
const stickyOuter = document.querySelector('.rail__sticky');
const stickyContainer = document.querySelector('.right-rail-sticky');
const rightRail = stickyContainer && stickyContainer.closest('.rail');
const enableRightRailSticky = HP && HP.params && HP.params.features && HP.params.features.rightRailSticky;

if (stickyContainer && rightRail && enableRightRailSticky) {
  document.addEventListener('scroll', () => {
    const stickyOuterRect = stickyOuter.getBoundingClientRect();
    const rightRailRect = rightRail.getBoundingClientRect();
    const maxY = rightRailRect.bottom;

    let stickyNavHeight = 140;
    if (stickyNav) {
      const stickyNavRect = stickyNav.getBoundingClientRect();
      stickyNavHeight = stickyNavRect.top + stickyNavRect.height;
    }
    const stickyTop = stickyOuterRect.top <= stickyNavHeight;
    const stickyBottom = maxY <= stickyContainer.offsetHeight + stickyNavHeight;
    stickyContainer.classList.toggle('sticky', stickyTop && !stickyBottom);
    stickyContainer.classList.toggle('sticky-bottom', stickyBottom);
    stickyContainer.style.top = stickyBottom ? '' : `${stickyNavHeight}px`;
    stickyContainer.style.width = `${rightRail.offsetWidth}px`;
  });

  document.addEventListener('see-more', () => {
    if (window.adsReloadAd) {
      const theAd = stickyContainer.querySelector('.ad-right_rail_flex') || stickyContainer.querySelector('.ad-right_rail_300x250_lower');
      if (theAd) {
        window.adsReloadAd(theAd);
      }
    }
  });
}
